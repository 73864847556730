<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Lihat Data Tugas Akhir
        <b-dropdown aria-role="list" slot="right" v-if="['admin','adminkbk'].indexOf(currentRole) >= 0">
            <template #trigger="{ active }">
                <b-button
                    label="Unduh Berkas"
                    type="is-info"
                    :icon-right="active ? 'menu-up' : 'menu-down'" />
            </template>

            <b-dropdown-item aria-role="listitem" @click="downloadBA()">BA Akhir</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="downloadBA('ketua_sidang')">Form Ketua Sidang</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="downloadBA('penguji1')">Form Penguji Pertama</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="downloadBA('penguji2')">Form Penguji Kedua</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="downloadBA('pembimbing1')">Form Pembimbing</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="downloadBA('catatan')">Catatan Perbaikan</b-dropdown-item>
        </b-dropdown>
    </hero-bar>
    <section class="section is-main-section">
      <tiles>

        <card-component title="Informasi Tugas Akhir" icon="book" class="tile is-child">
          <b-field label="Judul">
            <p>{{ data.title }}</p>
          </b-field>
          <b-field label="Mahasiswa">
            <b-input :value="data.mahasiswa" custom-class="is-static" readonly />
          </b-field>
          <b-field label="KBK">
            <b-input :value="data.kbk" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Pembimbing Pertama">
            <b-input :value="data.pembimbing1" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Pembimbing Kedua">
            <p>{{ data.pembimbing2 ? data.pembimbing2 : '-' }}</p>
          </b-field>
        </card-component>

        <card-component
          title="Informasi Sidang"
          icon="book"
          class="tile is-child"
        >
          <form @submit.prevent="submit">
            <b-field grouped group-multiline>
                <b-field label="Ketua Sidang">
                    <b-input :value="data.ketua_sidang" custom-class="is-static" readonly />
                </b-field>
                <b-field label="Penguji Pertama">
                    <b-input :value="data.penguji1" custom-class="is-static" readonly />
                </b-field>
                <b-field label="Penguji Kedua">
                    <b-input :value="data.penguji2" custom-class="is-static" readonly />
                </b-field>
            </b-field>
            <hr>
            <b-field grouped group-multiline>
                <b-field label="Nilai Ketua Sidang" v-if="['admin','adminkbk'].indexOf(currentRole) >= 0 || ['Ketua Sidang','Pembimbing'].indexOf(getPositionThesis) >= 0">
                    <b-input v-if="data.nilai_ketua_sidang" :value="data.nilai_ketua_sidang.average.toFixed(1)" custom-class="is-static" readonly />
                </b-field>
                <b-field label="Nilai Penguji Pertama" v-if="['admin','adminkbk'].indexOf(currentRole) >= 0 || ['Ketua Sidang','Pembimbing'].indexOf(getPositionThesis) >= 0 || getPositionThesis === 'Penguji 1'">
                    <b-input v-if="data.nilai_penguji1" :value="data.nilai_penguji1.average.toFixed(1)" custom-class="is-static" readonly />
                </b-field>
                <b-field label="Nilai Penguji Kedua" v-if="['admin','adminkbk'].indexOf(currentRole) >= 0 || ['Ketua Sidang','Pembimbing'].indexOf(getPositionThesis) >= 0 || getPositionThesis === 'Penguji 2'">
                    <b-input v-if="data.nilai_penguji2" :value="data.nilai_penguji2.average.toFixed(1)" custom-class="is-static" readonly />
                </b-field>
                <b-field label="Nilai Pembimbing" v-if="['admin','adminkbk'].indexOf(currentRole) >= 0 || ['Ketua Sidang','Pembimbing'].indexOf(getPositionThesis) >= 0">
                    <b-input v-if="data.nilai_pembimbing" :value="parseFloat(data.nilai_pembimbing).toFixed(1)" custom-class="is-static" readonly />
                </b-field>
            </b-field>
            <hr>
            <b-field grouped group-multiline>
                <b-field label="Nilai Akhir">
                    <b-input :value="data.nilai_akhir" custom-class="is-static" readonly />
                </b-field>
                <b-field label="Nilai Mutu">
                    <b-input :value="data.nilai_mutu" custom-class="is-static" readonly />
                </b-field>
                <b-field label="Tanggal Sidang">
                    <b-input :value="data.tanggal_sidang" custom-class="is-static" readonly />
                </b-field>
                <b-field label="Sesi">
                    <b-input :value="data.sesi" custom-class="is-static" readonly />
                </b-field>
            </b-field>

          </form>
        </card-component>
      </tiles>

      <tiles>
        <card-component title="Catatan Tugas Akhir" icon="book" class="tile is-child">
          <b-field label="Catatan Pembimbing Pertama">
            <p>{{ data.catatan_pembimbing ? data.catatan_pembimbing : '-' }}</p>
          </b-field>
          <b-field label="Catatan Ketua Sidang">
            <p>{{ data.catatan_ketua_sidang ? data.catatan_ketua_sidang : '-' }}</p>
          </b-field>
          <b-field label="Catatan Penguji Pertama">
            {{ data.catatan_penguji1 ? data.catatan_penguji1 : '-' }}
          </b-field>
          <b-field label="Catatan Penguji Kedua">
            <p>{{ data.catatan_penguji2 ? data.catatan_penguji2 : '-' }}</p>
          </b-field>
        </card-component>

      </tiles>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'

export default {
  name: 'Form',
  components: {
    CardComponent,
    Tiles,
    HeroBar,
    TitleBar
  },
  data () {
    return {
      active: false,
      data: {
        title: null
      }
    }
  },
  computed: {
    baUrl () {
      return `${axios.defaults.baseURL}/print-ba/${this.data.id}`
    },
    currentRole () {
      return this.$store.getters.currentRole
    },
    getPositionThesis () {
      const userID = parseInt(this.$store.state.user.user.id)

      if (parseInt(this.data.pembimbing1_id) === userID || parseInt(this.data.pembimbing2_id) === userID) return 'Pembimbing'
      if (parseInt(this.data.ketua_sidang_id) === userID) return 'Ketua Sidang'
      if (parseInt(this.data.penguji1_id) === userID) return 'Penguji 1'
      if (parseInt(this.data.penguji2_id) === userID) return 'Penguji 2'

      return false
    },
    titleStack () {
      return ['Dashboard', 'Tugas Akhir', 'Lihat Data Tugas Akhir']
    }
  },
  created () {
    this.getData()
  },
  methods: {
    downloadBA (position) {
      if (!position && (!this.data.nilai_pembimbing || !this.data.nilai_ketua_sidang || !this.data.nilai_penguji1 || !this.data.nilai_penguji2)) {
        alert('Pengisian nilai belum lengkap oleh pelaksana sidang harap  menunggu sampai pelaksana yang lain selesai menilai')
        return false
      }

      if (position === 'ketua_sidang' && !this.data.nilai_ketua_sidang) {
        alert('Pengisian nilai belum lengkap oleh pelaksana sidang harap menunggu sampai pelaksana selesai menilai')
        return false
      }

      if (position === 'pembimbing1' && !this.data.nilai_pembimbing) {
        alert('Pengisian nilai belum lengkap oleh pelaksana sidang harap menunggu sampai pelaksana selesai menilai')
        return false
      }

      if (position === 'penguji1' && !this.data.nilai_penguji1) {
        alert('Pengisian nilai belum lengkap oleh pelaksana sidang harap menunggu sampai pelaksana selesai menilai')
        return false
      }

      if (position === 'penguji2' && !this.data.nilai_penguji2) {
        alert('Pengisian nilai belum lengkap oleh pelaksana sidang harap menunggu sampai pelaksana selesai menilai')
        return false
      }

      window.location.href = position ? `${this.baUrl}/${position}` : `${this.baUrl}`
    },
    getData () {
      this.isLoading = true
      axios
        .get(`/thesis/${this.$route.params.id}`)
        .then((r) => {
          this.isLoading = false
          if (r.data && r.data.data) {
            if (r.data.data.length > this.perPage) {
              this.paginated = true
            }
            this.data = r.data.data
          }
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
    }
  }
}
</script>
